import React, { useEffect, useState } from "react"
import { Layout, SEO } from "../components"
import { isBrowser } from "../components/shared"
const title = "Call with Ricky"

const CallWithRicky = () => {
  let isMobileIosSafari
  const [sectionClass, setSectionClass] = useState("call-with-ricky")

  useEffect(() => {
    isMobileIosSafari =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("iPhone") != -1
    if (isMobileIosSafari) {
      setSectionClass("call-with-ricky iphone")
    }
  })

  return (
    <Layout noFooter title={title}>
      <SEO title={title} />
      <section className={sectionClass}>
        {isBrowser() ? (
          <iframe
            src={`https://calendly.com/ricky-future-foundry/30min?text_color=1b1c1d&primary_color=021f3f`}
            style={{ width: "1px", minWidth: "100%" }}
            frameBorder={0}
          ></iframe>
        ) : (
          ""
        )}
      </section>
    </Layout>
  )
}

export default CallWithRicky
